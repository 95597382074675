import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5331ed78"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "measures"
};
var _hoisted_2 = {
  class: "foot-measure"
};
var _hoisted_3 = {
  class: "digital-measure"
};
var _hoisted_4 = {
  class: "main-title"
};
var _hoisted_5 = {
  class: "foot-measure-content"
};
var _hoisted_6 = {
  class: "measure-item-video"
};
var _hoisted_7 = ["poster", "src"];
var _hoisted_8 = {
  class: "measure-title"
};
var _hoisted_9 = {
  class: "step-measure mt640"
};
var _hoisted_10 = {
  class: "digital-measure"
};
var _hoisted_11 = {
  class: "main-title"
};
var _hoisted_12 = {
  class: "foot-measure-content"
};
var _hoisted_13 = {
  class: "measure-item-video"
};
var _hoisted_14 = ["poster", "src"];
var _hoisted_15 = {
  class: "measure-title"
};
import { ref } from "vue";
export default {
  __name: 'foot-measurement',
  setup: function setup(__props) {
    var digitalMeasureItems = [{
      id: 0,
      label: window.$t("solution.scanning"),
      isVideo: true,
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/digital-measure1-new.png",
      video_url: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/video/digital-measure1.mp4"
    }, {
      id: 1,
      label: window.$t("solution.footMultiMatrix"),
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/digital-measure2-new-1.png",
      isVideo: true,
      video_url: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/video/digital-measure2.mp4"
    }, {
      id: 2,
      label: window.$t("solution.footThreeD"),
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/digital-measure3-new.png",
      isVideo: true,
      video_url: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/video/digital-measure3.mp4"
    }, {
      id: 3,
      label: window.$t("solution.footData"),
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/digital-measure4-new.png",
      isVideo: true,
      video_url: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/video/digital-measure4-new.mp4"
    }];
    var digitalMeasureItems2 = [{
      id: 0,
      label: window.$t("solution.simulation"),
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/digital-measure1-new.png",
      isVideo: true,
      video_url: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/video/digital-measure1.mp4"
    }, {
      id: 1,
      label: window.$t("solution.highPrecision"),
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/digital-measure2-new.png",
      isVideo: true,
      video_url: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/video/digital-measure2-new-2.mp4"
    }, {
      id: 2,
      label: window.$t("solution.intelligent"),
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/digital-measure3-new.png",
      isVideo: true,
      video_url: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/video/digital-measure2.mp4"
    }, {
      id: 3,
      label: window.$t("solution.footHealth"),
      img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/digital-measure4-new-4.png",
      isVideo: true,
      video_url: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/video/digital-measure4.mp4"
    }];
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("solution.smartFootMeasurement")), 1)]), _createElementVNode("div", _hoisted_5, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(digitalMeasureItems, function (item) {
        return _createElementVNode("div", {
          key: item.id,
          class: "foot-measure-item"
        }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("video", {
          class: "video",
          muted: "",
          autoplay: "",
          poster: item.img,
          src: item.video_url,
          preload: "auto",
          loop: "",
          style: {
            "border-radius": "32px"
          }
        }, _toDisplayString(_ctx.$t("solution.supportVideoTips")) + "。 ", 9, _hoisted_7)]), _createElementVNode("div", _hoisted_8, _toDisplayString(item.label), 1)]);
      }), 64))])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("solution.plantarPressure")), 1)]), _createElementVNode("div", _hoisted_12, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(digitalMeasureItems2, function (item) {
        return _createElementVNode("div", {
          key: item.id,
          class: "foot-measure-item"
        }, [_createElementVNode("div", _hoisted_13, [_createElementVNode("video", {
          class: "video",
          muted: "",
          autoplay: "",
          poster: item.img,
          src: item.video_url,
          preload: "auto",
          loop: "",
          style: {
            "border-radius": "32px"
          }
        }, _toDisplayString(_ctx.$t("solution.supportVideoTips")) + "。 ", 9, _hoisted_14)]), _createElementVNode("div", _hoisted_15, _toDisplayString(item.label), 1)]);
      }), 64))])])]);
    };
  }
};