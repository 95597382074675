import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-bf5e25de"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "solution"
};
import { ref, onMounted, onBeforeUnmount } from "vue";
import DigitalCustomBanner from "@/views/solution/digital-custom-module/digitalCustomBanner.vue";
import FootMeasurement from "@/views/solution/digital-custom-module/foot-measurement.vue";
import DigitalDesign from "@/views/solution/digital-custom-module/digital-design.vue";
import Manufacturing from "@/views/solution/digital-custom-module/manufacturing.vue";
import FlexibleProduct from "@/views/solution/digital-custom-module/flexible-product.vue";
import ApplicationExperienceModal from "@/views/solution/digital-custom-module/application-experience-modal.vue";
import Scroll from "@/utils/scroll.js";
export default {
  __name: 'index',
  setup: function setup(__props) {
    var isShowContactModal = ref(false);
    var openApplication = function openApplication() {
      isShowContactModal.value = true;
    };
    var scroll = new Scroll();
    var secondRef = ref(null);
    var thirdRef = ref(null);
    var fourthRef = ref(null);
    onBeforeUnmount(function () {
      scroll.dispose();
    });
    var mousewheelHandler = function mousewheelHandler(e) {
      e.preventDefault();
    };
    var closeModal = function closeModal() {
      isShowContactModal.value = false;
    };
    return function (_ctx, _cache) {
      var _component_n_drawer = _resolveComponent("n-drawer");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(DigitalCustomBanner, {
        onOpenApplication: openApplication
      }), _createVNode(FootMeasurement, {
        ref_key: "secondRef",
        ref: secondRef
      }, null, 512), _createVNode(DigitalDesign, {
        ref_key: "thirdRef",
        ref: thirdRef
      }, null, 512), _createVNode(Manufacturing, {
        ref_key: "fourthRef",
        ref: fourthRef
      }, null, 512), _createVNode(FlexibleProduct), _createVNode(_component_n_drawer, {
        show: _unref(isShowContactModal),
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(isShowContactModal) ? isShowContactModal.value = $event : isShowContactModal = $event;
        }),
        "close-on-esc": false,
        "mask-closable": false,
        width: 810,
        placement: "left"
      }, {
        default: _withCtx(function () {
          return [_createVNode(ApplicationExperienceModal, {
            onClose: closeModal
          })];
        }),
        _: 1
      }, 8, ["show"])]);
    };
  }
};