/**
 * 滚动事件库，目前针对滚动文本渐变效果
 */
export default class Scroll {
  screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight // 可视内容高度
  oldScrollTop = 0 // 记录上一次文档滚动的位置判断是向上还是向下
  ratio = 1

  constructor() {}

  init(domList) {
    document.addEventListener("scroll", () => this.handleScroll(domList))
    this.computedSize()
  }

  dispose() {
    document.removeEventListener("scroll", () => this.handleScroll())
  }

  handleScroll(domList = []) {
    domList.forEach((dom) => {
      this.handleTextBlock(dom)
    })
    this.oldScrollTop = document.documentElement.scrollTop
  }

  /**
   * 计算每块文字中的每行起止位置
   * @param  {HTMLDivElement} blockDom
   * @param  {Number} startPos // 可以传入自定义的开始位置(不建议传入太大的值)
   */
  handleTextBlock(blockDom, startPos) {
    // console.log("blockDom", blockDom)
    const len = blockDom.children.length // 计算有几行文本
    const firstLineHeight = blockDom.children.item(0).getBoundingClientRect().height
    startPos = startPos || this.screenHeight - firstLineHeight * this.ratio // 默认开始位置是底部往上100
    let endPos = 0
    let lineHeight = 40 * this.ratio
    // console.log(startPos, endPos)
    for (let i = 0; i < len; i++) {
      lineHeight += blockDom.children.item(i).getBoundingClientRect().height
      endPos = this.screenHeight - lineHeight
      // console.log(blockDom.children.item(i), startPos, endPos)
      this.handleTextLine(blockDom.children.item(i), startPos, endPos)
      startPos = endPos // 把上一行的结束位置赋值给当前的开始位置
    }
  }

  /**
   * 处理单行及单个字的效果
   * @param {HTMLDivElement} lineDom // 每行文字的dom对象
   * @param {Number} startPos // 每行开始的位置
   * @param {Number} endPos // 每行结束的位置
   */
  handleTextLine(lineDom, startPos, endPos) {
    // console.log("handleTextStyle3:lineDom", lineDom)
    const { top } = lineDom.getBoundingClientRect()
    const length = lineDom.children.length
    const everyWordHeight = (startPos - endPos) / length
    // console.log("startPos", startPos)
    // console.log("endPos", endPos)
    // console.log("top", top)
    // console.log("everyWordHeight", everyWordHeight)
    if (top <= startPos) {
      let num = 0
      if (document.documentElement.scrollTop >= this.oldScrollTop) {
        num = Math.ceil((startPos - top) / everyWordHeight)
      } else {
        num = Math.floor((startPos - top) / everyWordHeight)
      }
      //   console.log(num)

      for (let i = 0; i < length; i++) {
        if (i <= num - 1) {
          lineDom.children.item(i).style.opacity = 1
        } else {
          lineDom.children.item(i).style.opacity = 0.4
        }
      }
    }
  }

  /**
   * 计算浏览器适配尺寸计算
   */
  computedSize() {
    window.addEventListener("resize", () => {
      this.screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      this.oldScrollTop = document.documentElement.scrollTop
      this.ratio = this.screenHeight / 969
    })
  }
}
